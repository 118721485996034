import { Box } from '@mui/material';
import chevronRight from 'assets/images/chevronRight.svg';

interface ArrowProps {
  direction?: 'left' | 'right';
  onClick?: () => void;
}

export const Arrow = ({ direction, onClick }: ArrowProps) => {
  return (
    <Box
      sx={{ cursor: 'pointer' }}
      height={64}
      width={64}
      borderRadius={50}
      bgcolor="#CBF0E6"
      display="flex"
      justifyContent="center"
      alignItems="center"
      onClick={onClick}
    >
      <Box
        height={52}
        width={52}
        borderRadius={50}
        bgcolor="#B3E5E5"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          cursor: 'pointer',
          transform: direction == 'left' ? 'scale(-1,1)' : undefined,
          '& img': {
            height: 13,
            cursor: 'pointer',
          },
        }}
      >
        <img src={chevronRight.src} alt="arrow" />
      </Box>
    </Box>
  );
};
