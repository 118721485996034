import { Box } from '@mui/material';

import { NextPage } from 'next';
import { Achievements } from './Achievements';
import { Hero } from './Hero';
import { JoinNow } from './JoinNow';
import { OurPartners } from './OurPartners';
import { TestimonialCarousel } from './Testimonial/TestimonialCarousel';
import { WhyPod } from './WhyPod';

const InvestPage: NextPage = () => {
  return (
    <Box>
      <Hero />
      <WhyPod />
      <Achievements />
      {/* <Testimonial /> */}
      <TestimonialCarousel />
      <OurPartners />
      <JoinNow />
    </Box>
  );
};

export default InvestPage;
